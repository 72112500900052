<template>
  <div class="learn">
    <b-container>
      <b-row>
        <b-col sm="12" lg="7" class="blog-data">
          <img src="../assets/learn.png" alt="Learn Graphic" class="w-75 mb-5">
          <h1>Learning Center</h1>
          <div v-for="(section, index) in Object.keys(entries)" :key="index" class="group mt-4">
            <div class="section" v-for="entry in entries[section]" :key="entry.id">
              <div class="entry my-4">
                <h5 @click="$router.push({name: entry.id})" class="text-primary mb-1">
                  {{entry.title}}
                </h5>
                <p class="subtitle mb-2 text-gray">{{entry.date}}</p>
                <p>{{entry.description}}</p>
                <button class="btn btn-more" @click="$router.push({name: entry.id})">Read More</button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" xl="1" class="d-none d-xl-block"></b-col>
        <b-col sm="12" lg="5" xl="4" class="px-lg-0 " data-spy="affix" data-offset-top="205">
          <GetStarted/>
          <TopProviders class="w-100 mt-5"/>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import BLOGENTRIES from '@/statics/data/blogs.json'
import GetStarted from '@/components/GetStarted.vue'
import TopProviders from '@/components/TopProviders.vue'

export default {
  name: 'home',
  computed: {
    entries() {
      return BLOGENTRIES
    }
  },
  components: {
    GetStarted,
    TopProviders
  }
}
</script>

<style lang="scss">
.learn {
  padding-top: 30px;
  padding-bottom: 30px;

  @media only screen and (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.entry {
  h5 {
    cursor: pointer;
  }
}
</style>
